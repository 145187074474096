import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { useTranslations } from '../hooks/use-translations'
import Button from '../components/button/button'
import NewsSectionBlock from '../components/NewsSectionBlock'
import SEO from '../components/seo'
import Typography from '../components/Common/Typography'

import * as s from '../pages/news.module.scss'
import TryForFreeForm from '../components/contact-form/TryForFreeForm'

const RamadanSpecialOffer: React.FC = () => {
  const { t } = useTranslations()
  const [modal, setModal] = React.useState(false)
  return (
    <>
      <SEO
        title="SIGNAX Ramadan Special offer"
        description="Ramadan Kareem. Request a demonstration and get the pilot project for free only in Ramadan time"
      />
      <NewsSectionBlock date="23.03.2023">
        <Typography variant="h1" color="blue">
          SIGNAX Ramadan Special offer
        </Typography>
        <Typography variant="body1">
          Ramadan Kareem. Request a demonstration and get the pilot project for
          free only in Ramadan time
        </Typography>
        <div className={s.imageWrap}>
          <StaticImage
            width={580}
            src="../assets/images/news/ramadan-special-offer/preview.png"
            alt="SIGNAX Ramadan Special offer"
            title="SIGNAX Ramadan Special offer"
            placeholder="blurred"
          />
        </div>
        <Typography variant="body1">
          SIGNAX makes digital solutions for owners, engineering consultants and
          contractors helping them with scope management, tender packaging and
          cost control on the construction site.
        </Typography>
        <Typography variant="body1">
          You can request a business & technical demonstration of the product to
          understand how the construction processes can be more effective. You
          will also be able to identify your potential goals of digital
          implementation on the project.
        </Typography>
        <Typography variant="body1">
          Only now, you can get a pilot project with your BIM data absolutely
          for free. Our specialists will upload it into the system, make the
          construction model and show how you can make the real profit on site
          using it. Push the Request button below and go digital!
        </Typography>
        <div className="flex justify-center">
          <Button className="mt-8" onClick={() => setModal(true)}>
            Try for free
          </Button>
        </div>
        {modal && (
          <TryForFreeForm
            submitBtnText={t('Get login and password')}
            onClose={() => setModal(false)}
            title={t('Try for free')}
          />
        )}
      </NewsSectionBlock>
    </>
  )
}

export default RamadanSpecialOffer
